import * as React from "react";
import { Link } from "gatsby";
import HeaderMenu from "../components/HeaderMenu/HeaderMenu";
import { withLayout, LayoutProps, menuItems } from "../components/Layout";
import {
  Button,
  Segment,
  Container,
  Grid,
  Header,
  Image,
} from "semantic-ui-react";
import partner from "../../data/partner.json";

const IndexPage = (props: LayoutProps) =>
  <div>
    {/* Master head */}
    <Segment vertical inverted textAlign="center" className="masthead">
      <HeaderMenu
        Link={Link} pathname={props.location.pathname} items={menuItems} inverted
      />
      <Container text>
        <Header inverted as="h1">Surveillance Solutions</Header>
        <Header inverted as="h3">
          We sell and install alarm and video surveillance systems to keep your property and business secure.</Header>
      </Container>
    </Segment>

    {/* About this starter */}
    <Grid className="container stripe vertical" textAlign="center">
      <Grid.Row textAlign="center">
        <Header
          className="container"
          as="h3">Video Surveillance & Alarm Systems in Whitecourt & Surrounding Areas</Header>
        <p>
          Keep your business and property well protected with professional installations of alarm and video surveillance systems in Whitecourt, Fox Creek, and other surrounding areas. As well as installation, Surveillance Solutions takes care of the maintenance of your camera and alarm systems products after they are installed, to ensure that they are functioning properly and keeping your business secure. We’ve been performing low voltage work since 1996, and for the past 10 years we have gained much experience in surveillance systems, servicing government, healthcare, commercial, and industrial companies. Our team is made up of highly qualified employees and communications electricians.
        </p>
      </Grid.Row>
    </Grid>

    {/* Key features */}
    <Grid
      divided
      doubling
      container
      columns="3"
      relaxed="very"
      direction="row"
      textAlign="center"
      verticalAlign="middle"
      className="container stripe margin-bottom">
      <Grid.Row>
        <Grid.Column>
          <Header as="h4">Alarm systems installation</Header>
        </Grid.Column>
        <Grid.Column>
          <Header as="h4">Video camera installation</Header>
        </Grid.Column>
        <Grid.Column>
          <Header as="h4">Security camera sales</Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Header as="h4">Alarm testing and maintenance</Header>
        </Grid.Column>
        <Grid.Column>
          <Header as="h4">Video surveillance maintenance</Header>
        </Grid.Column>
        <Grid.Column>
          <Header as="h4">Access control</Header>
        </Grid.Column>
      </Grid.Row>
    </Grid>
    {/* About this starter */}
    <Segment vertical className="stripe alternate feature">
      <Grid className="container stripe" textAlign="center">
        <Grid.Row textAlign="center">
          <Header
            className="container"
            as="h3">HD Video Surveillance</Header>
          <p>
            Surveillance Solutions can set up you and your business so that you can remotely check on the security of your commercial space, merchandise, and more. We sell and install video surveillance cameras that take high definition (HD) images and video so that you can clearly identify people who are stealing or vandalizing your property. Our team strategically places the cameras around your space or property to ensure that all important angles can be viewed.
          </p>
        </Grid.Row>
      </Grid>
    </Segment>
    <Segment vertical className="stripe alternate feature">
      <Grid
        relaxed
        divided
        stackable
        columns="3"
        textAlign="center"
        verticalAlign="middle"
        className="container">
        <Grid.Column>
          <Image
            className="partner"
            src={partner[17].src}
            alt={partner[17].name}
            centered />
        </Grid.Column>
        <Grid.Column>
          <Image
            className="partner"
            src={partner[21].src}
            alt={partner[21].name}
            centered />
        </Grid.Column>
        <Grid.Column>
          <Image
            className="partner sm"
            src={partner[18].src}
            alt={partner[18].name}
            centered />
        </Grid.Column>
        <Grid.Column className="no-shadow">
          <Image
            className="partner"
            src={partner[19].src}
            alt={partner[19].name}
            centered />
        </Grid.Column>
        <Grid.Column>
          <Image
            className="partner"
            src={partner[22].src}
            alt={partner[22].name}
            centered />
        </Grid.Column>
        <Grid.Column>
          <Image
            className="partner"
            src={partner[16].src}
            alt={partner[16].name}
            centered />
        </Grid.Column>
      </Grid>
    </Segment>
  </div>;

export default withLayout(IndexPage);
